import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CoinService {
  constructor() {}

  private ticketSubject = new Subject<any>();
  ticketSubject$ = this.ticketSubject.asObservable();

  changed() {
    this.ticketSubject.next(new Date());
  }
}
