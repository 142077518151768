import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BESModalService, IModal } from '../../core/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UsuarioController } from '../../core/controllers/usuarios.controller';
import { AccessService } from '../../core/services/access.service';
import moment from 'moment';

@Component({
  selector: 'toy-cadastro',
  standalone: false,
  templateUrl: './cadastro.component.html',
})
export class ToyCadastroComponent implements OnInit {
  constructor(
    private modalService: BESModalService,
    private router: Router,
    private accessService: AccessService,
    private usuarioController: UsuarioController,
    private activatedRoute: ActivatedRoute
  ) {}

  @ViewChild('mc') mc?: any;
  showModalCadastro = false;
  configModal?: IModal;
  form!: UntypedFormGroup;
  errorMessage: string | null = null;
  @Output() onCadastroSuccess = new EventEmitter<void>();

  ngOnInit(): void {
    this.watchService();
    this.createForm();
  }

  createForm = () => {
    this.form = new UntypedFormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, [Validators.required]),
      offers: new FormControl(false),
      terms: new FormControl(false, [Validators.requiredTrue]),
    });
  };

  fazerCadastro = () => {
    this.errorMessage = null;
    

    if (this.form.invalid) {
      this.errorMessage = 'Por favor, preencha todos os campos corretamente.';
      return;
    }

    if (this.form.value.password !== this.form.value.confirmPassword) {
      this.errorMessage = 'As senhas não coincidem.';
      return;
    }

    const influencerId = sessionStorage.getItem('userInfluenceId');

    const newData = {
      email: this.form.value.email,
      password: this.form.value.confirmPassword,
      influencerId,
    };
    console.log(influencerId);
    

    this.usuarioController.createMyself(newData).subscribe({
      next: (response) => {
        this.accessService.access = response.data;
      },
      complete: () => {
        this.accessService.setAuthState(true);
        this.router.navigate(['/maquina']);
        this.onCadastroSuccess.emit();
      },
      error: (err) => {
        // Handle error
        console.error('Erro ao criar usuário', err);
      },
    });
  };

  resetFormAndError = () => {
    this.form.reset();
    this.errorMessage = null;
  };

  watchService = () => {
    this.modalService.modalSource.subscribe((resp: any) => {
      this.showModalCadastro = this.modalService.modalState;
      this.configModal = resp;
    });
  };

  close = () => {
    this.modalService.hide();
  };

  confirm = () => {
    if (this.configModal?.btnConfirmEvent) {
      this.configModal.btnConfirmEvent();
    }

    this.modalService.hide();
  };

  exibirModalCadastro = () => {
    console.log('modal');
    this.showModalCadastro = true;
  };

  clearErrorMessage = () => {
    this.errorMessage = null;
  };

  // redirectMaquina = () => {
  //   this.router.navigate(['/maquina'])
  // }
}
