import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BESModalService, IModal } from '../../core/services/modal.service';
import { ToyLoginComponent } from '../login/login.component';

@Component({
  selector: 'toy-modal-login',
  standalone: false,
  templateUrl: 'modal-login.component.html',
})
export class ToyModalLoginComponent implements OnInit {
  @ViewChild(ToyLoginComponent) loginComponent!: ToyLoginComponent;
  showModalLogin = false;

  constructor() {}
  ngOnInit(): void {}

  exibirModalLogin = () => {
    this.showModalLogin = true;
    if (this.loginComponent) {
      this.loginComponent.resetFormAndError();
    }
  };

  doLoginSuccess = () => {
    this.showModalLogin = false;
  };

  onModalClose = () => {
    this.showModalLogin = false;
    if (this.loginComponent) {
      this.loginComponent.resetFormAndError();
    }
  };
}
