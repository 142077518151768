import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { MaquinaPartidaController } from '../../core/controllers/maquina-partida.controller';

@Component({
  selector: 'toy-maquina-card',
  templateUrl: './maquina-card.component.html',
})
export class MaquinaCardComponent implements OnInit {
  maquina: any;
  constructor(
    private _router: Router,
    private maquinaPartidaController: MaquinaPartidaController
  ) {}

  @Input() item: any;
  @Input() uso: boolean = false;

  ngOnInit(): void {}

  clickImg = () => {
    this._router.navigate(['login']);
  };

  clickCard = () => {
    const machineId = this.item.id;
    this._router.navigate([`play/${machineId}`]);
  };

}
