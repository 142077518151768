import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion'
import { ToyHomeMobileComponent } from './home-mobile.component';
import { ToyModalCadastroModule } from '../modal-cadastro/modal-cadastro.module';
import { ToyModalLoginModule } from '../modal-login/modal-login.module';

@NgModule({
  imports: [ButtonModule, DividerModule, AccordionModule, ToyModalCadastroModule, ToyModalLoginModule],
  declarations: [ToyHomeMobileComponent],
  exports: [ToyHomeMobileComponent],
})
export class ToyHomeMobileModule {}
