import { NgModule } from '@angular/core';
import { HospButtonOptionModule } from './button-option/button-option.module';
import { ToyModalCadastroModule } from './modal-cadastro/modal-cadastro.module';
import { ToyModalLoginModule } from './modal-login/modal-login.module';
import { ToyModalPayModule } from './modal-pay/modal-pay.module';
import { HospLoadingModule } from './loading/loading.module';
import { MaquinaCardModule } from './maquina-card/maquina-card.module';
import { FichaCardModule } from './ficha-card/ficha-card.module';
import { TrocaCardModule } from './troca-card/troca-card.module';
import { ToyFooterModule } from './footer/footer.module';
import { ToyTemplateModule } from './template/template.module';
import { ToySidebarModule } from './sidebar/sidebar.module';
import { ToyHeaderModule } from './header/header.module';
import { ToyColapseModule } from './colapse/colapse.module';
import { ToyAvaliacaoModule } from './avaliacao/avaliacao.module';
import { ToyLoadingMaquinaModule } from './loading-maquina/loading-maquina.module';
import { ToyHomeModule } from './home/home.module';
import { ToyModalJogoModule } from './modal-jogo/modal-jogo.module';
import { ToyPerfilModule } from './perfil/perfil.module';
import { ToyHomeMobileModule } from './home-mobile/home-mobile.module';
import { ToyLoginModule } from './login/login.module';
import { ToyCadastroModule } from './cadastro/cadastro.module';
import { ToyModalDialogModule } from './modal-dialog/modal-dialog.module';

@NgModule({
  imports: [
    ToyHeaderModule,
    ToyFooterModule,
    ToyTemplateModule,
    HospButtonOptionModule,
    ToyModalCadastroModule,
    ToyModalLoginModule,
    ToyModalPayModule,
    HospLoadingModule,
    ToyLoginModule,
    ToyCadastroModule,
    MaquinaCardModule,
    FichaCardModule,
    TrocaCardModule,
    ToySidebarModule,
    ToyColapseModule,
    ToyLoadingMaquinaModule,
    ToyHomeModule,
    ToyAvaliacaoModule,
    ToyModalJogoModule,
    ToyModalDialogModule,
    ToyPerfilModule,
    ToyHomeMobileModule,
  ],
  declarations: [],
  exports: [
    ToyHeaderModule,
    ToyFooterModule,
    ToyTemplateModule,
    HospButtonOptionModule,
    ToyModalCadastroModule,
    ToyModalLoginModule,
    ToyModalPayModule,
    HospLoadingModule,
    ToyLoginModule,
    ToyCadastroModule,
    MaquinaCardModule,
    FichaCardModule,
    TrocaCardModule,
    ToySidebarModule,
    ToyColapseModule,
    ToyLoadingMaquinaModule,
    ToyHomeModule,
    ToyAvaliacaoModule,
    ToyModalJogoModule,
    ToyModalDialogModule,
    ToyPerfilModule,
    ToyHomeMobileModule,
  ],
})
export class HospComponentsModule {}
