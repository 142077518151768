export const ENUM_STATUS = {
  ATIVO: 1,
  INATIVO: 2,

  PARTIDA_EM_JOGO: 3,
  PARTIDA_FINALIZADO: 4,
  PARTIDA_CANCELADO: 5,
  PARTIDA_NA_FILA: 6,
  PARTIDA_AGUARDANDO_RESULTADO: 12,

  PEDIDO_AGUARDANDO_PAGAMENTO: 13,
  PEDIDO_PAGAMENTO_RECEBIDO: 7,
  PEDIDO_SEPARADO: 8,
  PEDIDO_EMBALADO: 9,
  PEDIDO_FINALIZADO: 10,

  CARRINHO_FINALIZADO: 11,
};

export const ENUM_TIPO_USER = {
  ADMIN: 1,
  JOGADOR: 2,
  INFLUENCER: 3,
};

export const CONFIGURATIONS = {
  MULTIPLICADOR_PLUSHCOIN: 'MULTIPLICADOR_PLUSHCOIN',
};
