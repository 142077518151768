import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'toy-modal-dialog',
  standalone: false,
  templateUrl: 'modal-dialog.component.html',
})
export class ToyModalDialogComponent implements OnInit {
  showModalDialog = false;

  title: string = '';
  subtitle: string = '';

  constructor() {}

  ngOnInit(): void {}
  exibirModalDialog = (title: string, subtitle: string) => {
    this.title = title;
    this.subtitle = subtitle;
    this.showModalDialog = true;
  };

  onModalClose = () => {
    this.showModalDialog = false;
  };
}
