import { NgModule } from '@angular/core';
import { ToyCadastroComponent } from './cadastro.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ButtonModule, DialogModule, ReactiveFormsModule],
  declarations: [ToyCadastroComponent],
  exports: [ToyCadastroComponent],
})
export class ToyCadastroModule {}