import { Component, Input, OnInit } from '@angular/core';
import { AccessService } from '../../core/services/access.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ENUM_TIPO_USER } from '../../core/helpers/enum';

@Component({
  selector: 'toy-perfil',
  templateUrl: './perfil.component.html',
})
export class ToyPerfilComponent implements OnInit {
  constructor(private accessService: AccessService, private _router: Router) {}

  items: MenuItem[] = [];

  ngOnInit(): void {
    this.items = [
      {
        label: 'Conta',
        command: () => this.navigateTo('perfil/conta'),
      },
      {
        label: 'Depósitos e Saques',
        command: () => this.navigateTo('perfil/deposito-saque'),
      },
      {
        label: 'Afiliados',
        command: () => this.navigateTo('perfil/afiliados'),
      },
      {
        label: 'Histórico de jogos',
        command: () => this.navigateTo('perfil/historico-jogos'),
      },
      {
        label: 'Meus Pedidos',
        command: () => this.navigateTo('perfil/meus-pedidos'),
      },
      {
        label: 'Sair',
        command: () => this.logout(),
      },
    ];

    if (this.accessService.access.typeUserId != ENUM_TIPO_USER.INFLUENCER) {
      this.items.splice(2, 1);
    }
  }

  navigateTo(route: string) {
    this._router.navigate([route]);
  }

  logout() {
    this.accessService.sair();
    this._router.navigate(['/']);
  }
}
