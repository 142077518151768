import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBESService } from '../services/api-bes.service';

@Injectable({ providedIn: 'root' })
export class AuthController {
  constructor(private api: ApiBESService) {}
  private prefix = '/auth';

  login = (data: any): Observable<any> => {
    return this.api.post(`${this.prefix}`, data, false);
  };
}
