<form [formGroup]="form">
  <input
    formControlName="email"
    type="text"
    placeholder="Email"
    class="inputEmail mb-3"
    (focus)="clearErrorMessage()"
  />
  <input
    formControlName="password"
    type="password"
    placeholder="Senha"
    class="inputSenha mb-3"
    (focus)="clearErrorMessage()"
  />
  <input
    formControlName="confirmPassword"
    type="password"
    placeholder="Confirmar senha"
    class="inputSenha mb-3"
    (focus)="clearErrorMessage()"
  />

  @if (errorMessage) {
    <div class="text-center mb-3 bes-color-destructive">
      <i class="pi pi-times-circle me-1"></i>{{ errorMessage }}</div>
  }

  <div class="checkboxes-group">
    <div class="checkboxes">
      <input type="checkbox" class="" name="chk-offerts" id="" formControlName="offers" />
      <p class="">Concordo em receber<br />ofertas e promoções do site</p>
    </div>
    <div class="checkboxes">
      <input type="checkbox" name="chk-terms" formControlName="terms" id="" />
      <p>Eu aceito os <span>Termos de Serviço</span></p>
    </div>
  </div>

  <button class="btn-login" (click)="fazerCadastro()">SALVAR</button>
</form>
<!-- (click)="redirectMaquina()" -->
