import { NgModule } from '@angular/core';
import { ToyFooterModule } from '../footer/footer.module';
import { RouterOutlet } from '@angular/router';
import { ToyHeaderModule } from '../header/header.module';
import { ToyTemplateComponent } from './template.component';
import { ToySidebarModule } from '../sidebar/sidebar.module';
import { ToyModalLoginModule } from "../modal-login/modal-login.module";

@NgModule({
  imports: [ToyFooterModule, ToyHeaderModule, ToySidebarModule, RouterOutlet, ToyModalLoginModule],
  declarations: [ToyTemplateComponent],
  exports: [ToyTemplateComponent],
})
export class ToyTemplateModule {}
