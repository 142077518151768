import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'toy-home-mobile-componente',
  templateUrl: './home-mobile.component.html',
})
export class ToyHomeMobileComponent implements OnInit {


  constructor(private _router: Router) {}

  ngOnInit(): void {}

  
  redirectCadastroMobile = () => {
    this._router.navigate(['/cadastro'])
  }
  
  redirectLoginMobile = () => {
    this._router.navigate(['/login'])
  }
}
