import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessService } from '../../core/services/access.service';
import { CoinService } from '../../core/services/coin.service';

@Component({
  selector: 'toy-modal-pay',
  standalone: false,
  templateUrl: 'modal-pay.component.html',
})
export class ToyModalPayComponent implements OnInit {
  showModalPay = false;
  showModalPayQrcode = false;
  showModalPayWait = false;
  dados: any;

  constructor(
    private _router: Router,
    private accessService: AccessService,
    private coinService: CoinService
  ) {}

  isAuthenticated = false;

  ngOnInit(): void {
    const subRes = (isAuth: boolean) => {
      this.isAuthenticated = isAuth;
    };

    this.accessService.authState$.subscribe(subRes);
    this.accessService.check().subscribe(subRes);
  }

  exibirModalPay = (e: any) => {
    this.dados = e;
    this.showModalPay = true;
    this.showModalPayQrcode = true;
  };

  onModalClose = () => {
    this.showModalPay = false;
    this.showModalPayWait = false;
  };

  async copiarValor(): Promise<void> {
    try {
      await navigator.clipboard.writeText(this.dados.qrcodeValue);
    } catch (err) {
      console.error('Erro ao copiar o texto: ', err);
    }
  }

  setPayment = () => {
    this.coinService.changed();
    this.showModalPayWait = true;
    this.showModalPayQrcode = false;
  };

  redirectMaquinas = () => {
    this.showModalPayWait = false;
    this._router.navigate([`maquina`]);
  };
}
