import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AccessService } from '../../core/services/access.service';

@Component({
  selector: 'toy-home-componente',
  templateUrl: './home.component.html',
})
export class ToyHomeComponent implements OnInit {
  isAuthenticated = false;

  constructor(private _router: Router, private accessService: AccessService) {}

  @Input() ambienteDetails: any;
  @Input() isPainel: boolean = true;

  banners = [
    {
      url: './assets/project/banner-jogue.jpg',
      action: () => {
        this.clickGameOrLogin();
      },
    },
    {
      url: './assets/project/banner-bonus.jpg',
      action: () => {
        this.clickDeposito();
      },
    },
    {
      url: './assets/project/banner-real.jpg',
      action: () => {
        this.clickGameOrLogin();
      },
    },
  ];

  @ViewChild('ml') ml: any;

  userAgent: string =
    navigator.userAgent ||
    navigator.vendor ||
    (window as any).opera ||
    undefined;

  isMobileDevice = () => {
    const regexs = [
      /(Android)(.+)(Mobile)/i,
      /BlackBerry/i,
      /iPhone|iPod/i,
      /Opera Mini/i,
      /IEMobile/i,
    ];
    return regexs.some((b) => this.userAgent.match(b));
  };

  ngOnInit(): void {
    if (this.isMobileDevice()) {
      this._router.navigate(['home-mobile']);
    }
  }

  checkLogged = (callback: () => void) => {
    this.accessService.check().subscribe({
      next: (r) => {
        if (r) {
          callback();
        } else {
          this.ml.exibirModalLogin();
        }
      },
    });
  };

  clickGameOrLogin = () => {
    this.checkLogged(() => {
      this._router.navigate(['maquina']);
    });
  };

  clickDeposito = () => {
    this.checkLogged(() => {
      this._router.navigate(['deposito-saque']);
    });
  };
}
