import { LOCALE_ID, NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { ToyModalDialogComponent } from './modal-dialog.component';

@NgModule({
  imports: [ButtonModule, DialogModule, TooltipModule],
  declarations: [ToyModalDialogComponent],
  exports: [ToyModalDialogComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
})
export class ToyModalDialogModule {}
