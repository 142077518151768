<div class="container">
  <div class="row mt-5 mb-5">
    <div class="d-none d-md-flex col-12 d-flex justify-content-center">
      <h1 class="alegreyasans-black bes-fs-30">AVALIAÇÕES RECENTES</h1>
    </div>
  </div>
  <div class="row mb-5">
    <div class="d-none d-md-block col-4">
      <div class="card border-0">
        <div class="card-body bes-bg-color-primary-clean px-4 py-4">
          <p class="card-text bes-color-clean mb-5 poppins-regular bes-fs-16">
            A Fortune Plush é simplesmente incrível! Adorei a experiência de pegar pelúcias online, é muito divertido e desafiador. A plataforma é fácil de usar e o atendimento ao cliente é excelente. Mal posso esperar para participar de mais competições e ganhar prêmios exclusivos!
          </p>
          <br />
          <div class="row">
            <div class="col-6">
              <p class="poppins-bold bes-fs-17 bes-color-clean mb-1">
                Ana Pereira
              </p>
              <p class="poppins-regular bes-fs-16 bes-color-gray">
                28 de agosto de 2024
              </p>
            </div>
            <div class="col-6">
              <img src="./assets/project/Estrelas.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block col-4">
      <div class="card border-0">
        <div class="card-body bes-bg-color-primary-clean px-4 py-4">
          <p class="card-text bes-color-clean mb-5 poppins-regular bes-fs-16">
            Fiquei impressionado com a qualidade da Fortune Plush! É uma maneira única de passar o tempo, especialmente nos finais de semana. A interface é intuitiva e o jogo funciona sem problemas. Já ganhei algumas pelúcias e elas chegaram em perfeito estado. Recomendo para todos os amantes de jogos online!
          </p>
          <br />
          <div class="row">
            <div class="col-6">
              <p class="poppins-bold bes-fs-17 bes-color-clean mb-1">
                Lucas Andrade
              </p>
              <p class="poppins-regular bes-fs-16 bes-color-gray">
                15 de julho de 2024
              </p>
            </div>
            <div class="col-6">
              <img src="./assets/project/Estrelas.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block col-4">
      <div class="card border-0">
        <div class="card-body bes-bg-color-primary-clean px-4 py-4">
          <p class="card-text bes-color-clean mb-5 poppins-regular bes-fs-16">
            Adoro a Fortune Plush! A sensação de capturar uma pelúcia online é muito gratificante, e a variedade de prêmios disponíveis é excelente. O site é seguro, e a equipe de suporte sempre foi muito atenciosa nas vezes que precisei. É ótimo saber que podemos confiar em uma plataforma divertida e justa como esta.
          </p>
          <br />
          <div class="row">
            <div class="col-6">
              <p class="poppins-bold bes-fs-17 bes-color-clean mb-1">
                Camila Santos
              </p>
              <p class="poppins-regular bes-fs-16 bes-color-gray">
                10 de setembro de 2024
              </p>
            </div>
            <div class="col-6">
              <img src="./assets/project/Estrelas.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
