import { NgModule } from '@angular/core';
import { ToyFooterComponent } from './footer.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [ButtonModule],
  declarations: [ToyFooterComponent],
  exports: [ToyFooterComponent],
})
export class ToyFooterModule {}
