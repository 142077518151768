import { NgModule } from '@angular/core';
import { ToyLoginComponent } from './login.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ButtonModule, DialogModule, ReactiveFormsModule],
  declarations: [ToyLoginComponent],
  exports: [ToyLoginComponent],
})
export class ToyLoginModule {}