import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaquinaPartidaController } from '../../core/controllers/maquina-partida.controller';
import { ToyToastService } from '../../core/services/toast.service';
import { MachineController } from '../../core/controllers/machine.controller';

@Component({
  selector: 'toy-loading-maquina',
  templateUrl: './loading-maquina.component.html',
})
export class ToyLoadingMaquinaComponent implements OnInit {
  machineId: any;

  constructor(
    private _router: Router,
    private activateRouter: ActivatedRoute,
    private toyToastService: ToyToastService,
    private machineController: MachineController,
    private maquinaPartidaController: MaquinaPartidaController
  ) {}

  ngOnInit(): void {
    this.machineId = Number(this.activateRouter.snapshot.paramMap.get('id'));
    this.checkMachineStatus();
  }

  checkMachineStatus() {
    this.maquinaPartidaController.getEmUsoById(this.machineId).subscribe({
      next: (response: any) => {
        console.log(response);
        this.isInLine = response.data.meInLine;
        this.userName = response.data.userName;
        this.qtdInLine = parseInt(response.data.quantidade);
        if (
          (response.data.isEmUso && response.data.isUser) ||
          !response.data.isEmUso
        ) {
          this.redirectPlay();
        }
      },
      error: (err) => {
        console.error('Erro ao verificar status da máquina', err);
      },
    });
  }

  isInLine = false;
  qtdInLine = 0;
  userName = '';
  entrarNaFila = () => {
    if (this.machineId !== null) {
      this.maquinaPartidaController.create(this.machineId).subscribe({
        next: (e) => {
          this.machineController.credito(this.machineId).subscribe({
            next: (res) => {
              this.redirectPlay();
            },
          });
        },
        error: (err) => {
          this.toyToastService.error(err.message);
          this._router.navigate(['perfil/deposito-saque']);
        },
      });
    }
  };

  sairDaFila = () => {};

  redirectPlay = () => {
    this._router.navigate(['play', this.machineId]);
  };
}
