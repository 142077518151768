import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ToyPerfilComponent } from './perfil.component';
import { RouterOutlet } from '@angular/router';
import { TieredMenuModule } from 'primeng/tieredmenu';

@NgModule({
    imports: [ButtonModule, RouterOutlet, TieredMenuModule],
    declarations: [ToyPerfilComponent],
    exports: [ToyPerfilComponent],
})
export class ToyPerfilModule { }
