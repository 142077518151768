import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioController } from '../../core/controllers/usuarios.controller';
import { CoinService } from '../../core/services/coin.service';

@Component({
  selector: 'toy-ficha-card',
  templateUrl: './ficha-card.component.html',
})
export class FichaCardComponent implements OnInit {
  constructor(
    private _router: Router,
    private coinService: CoinService,
    private usuarioController: UsuarioController
  ) {}

  @Input() item: any;
  @Output() onClick = new EventEmitter<any>();

  ngOnInit(): void {}

  comprarFichaCard = (value: number) => {
    this.usuarioController.updateCreditos({ value }).subscribe({
      next: (r) => {        
        // this.coinService.changed();
        this.onClick.emit(r.data);
      },
    });
  };
}
