<div class="modal-login-style">
  <p-dialog
    header="LOGIN"
    [modal]="true"
    [(visible)]="showModalLogin"
    (onHide)="onModalClose()"
    [draggable]="false"
    [resizable]="false"
    styleClass="box-modal"
    [style]="{ width: '25rem' }"
  >
    <toy-login (onLoginSuccess)="doLoginSuccess()"></toy-login>
  </p-dialog>
</div>