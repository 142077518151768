<div [hidden]="!showLoading" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="bes-loading bes-loading-background">
        <img
          class="bes-w-60 loading-rotate"
          src="assets/project/loading.gif"
        />
      </div>
    </div>
  </div>
</div>
