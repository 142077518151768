import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthController } from '../../core/controllers/auth.controller';
import { AccessService } from '../../core/services/access.service';
import { Router } from '@angular/router';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'toy-login',
  standalone: false,
  templateUrl: 'login.component.html',
})
export class ToyLoginComponent implements OnInit {
  constructor(
    private authController: AuthController,
    private accessService: AccessService,
    private router: Router
  ) {}

  @Output() onLoginSuccess = new EventEmitter<void>();

  loginValue: any;

  errorMessage: string | null = null;
  form!: UntypedFormGroup;

  ngOnInit(): void {
    this.createForm();
  }

  createForm = () => {
    this.form = new UntypedFormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  };

  fazerLogin = () => {
    this.errorMessage = null;
    if (this.form.invalid) {
      this.errorMessage = 'Por favor, preencha todos os campos corretamente.';
      return;
    }

    this.authController.login(this.form.value).subscribe({
      next: (response) => {
        if (response && response.data) {
          this.accessService.access = response.data;
        }
      },
      complete: () => {
        this.accessService.setAuthState(true);
        this.router.navigate(['/maquina']);
        this.onLoginSuccess.emit();
      },
      error: (err) => {
        this.errorMessage = 'Usuário e ou senha inválidos';
        // console.error('Erro de login', err);
      },
    });
  };

  resetFormAndError = () => {
    this.form.reset();
    this.errorMessage = null;
  };

  clearErrorMessage = () => {
    this.errorMessage = null;
  };
}
