import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion'
import { ToyAvaliacaoComponent } from './avaliacao.component';

@NgModule({
  imports: [ButtonModule, DividerModule, AccordionModule],
  declarations: [ToyAvaliacaoComponent],
  exports: [ToyAvaliacaoComponent],
})
export class ToyAvaliacaoModule {}
