import { Injectable } from '@angular/core';
import { ApiBESService } from '../services/api-bes.service';

@Injectable({ providedIn: 'root' })
export class MaquinaPartidaController {
  constructor(private api: ApiBESService) {}
  private prefix = '/maquina-partida';

  getAll = () => {
    return this.api.get(`${this.prefix}`);
  };

  getById = (id: number) => {
    return this.api.get(`${this.prefix}/${id}`);
  };
  
  getHistoricoPartidas = () => {
    return this.api.get(`${this.prefix}/historico-partidas`);
  }

  getEmUso = () => {
    return this.api.get(`${this.prefix}/em-uso`);
  };

  getEmUsoById = (machineId: number) => {
    return this.api.get(`${this.prefix}/em-uso/${machineId}`);
  };

  create = (machineId: number, force: boolean = false) => {
    return this.api.post(`${this.prefix}`, { machineId, force });
  };

  marcarPontuacao = (machineMatchId: number, wins: boolean) => {
    return this.api.put(`${this.prefix}/pontuacao/${machineMatchId}`, { wins });
  };

  encerrarPartida = (machineMatchId: number) => {
    return this.api.delete(`${this.prefix}/${machineMatchId}`);
  };

  enviarCarrinho = (machineMatchId: number) => {
    return this.api.put(`${this.prefix}/enviar-carrinho/${machineMatchId}`, {});
  };

  vender = (machineMatchId: number) => {
    return this.api.put(`${this.prefix}/vender/${machineMatchId}`, {});
  };

  finalizar = (machineMatchId: number) => {
    return this.api.put(`${this.prefix}/finalizar/${machineMatchId}`, {});
  };

  cancelar = (machineMatchId: number) => {
    return this.api.put(`${this.prefix}/cancelar/${machineMatchId}`, {});
  };
}
