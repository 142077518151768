import { NgModule } from '@angular/core';
import { TrocaCardComponent } from './troca-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ReactiveFormsModule],
  declarations: [TrocaCardComponent],
  exports: [TrocaCardComponent],
})
export class TrocaCardModule {}
