import { NgModule } from '@angular/core';
import { FichaCardComponent } from './ficha-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ReactiveFormsModule],
  declarations: [FichaCardComponent],
  exports: [FichaCardComponent],
})
export class FichaCardModule {}
