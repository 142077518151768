import { NgModule } from '@angular/core';
import { ToyColapseComponent } from './colapse.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion'

@NgModule({
  imports: [ButtonModule, DividerModule, AccordionModule],
  declarations: [ToyColapseComponent],
  exports: [ToyColapseComponent],
})
export class ToyColapseModule {}
