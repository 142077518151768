<div class="row">
  <div class="col-12">
    <div class="maquina-card position-relative bes-h-261" (click)="clickCard()">
      <div class="position-absolute top-0 start-0 w-full z-1 ms-2 mt-2">
        <div class="content-top w-full bes-h-200 bes-bg-color-tertiary"></div>
        <div class="w-full d-flex">
          <div
            class="content-bottom bes-w-95-perc bes-h-20 bes-bg-color-tertiary"
          ></div>
          <div class="bes-w-5-perc borda-quadrada-externa"></div>
        </div>
      </div>
      <div class="position-absolute top-0 start-0 w-full z-2">
        <div
          class="content-top w-full bes-h-200 bes-bg-color-primary border border-bottom-0 bes-border-color-secondary border-2 px-4"
        >
          <div class="row">
            <div class="col-12 bes-h-140 gradient-tertiary mt-3">
              <div class="row">
                <div class="col-12 bes-h-30">
                  <div class="row">
                    @if(uso){
                    <div class="semaforo position-absolute">
                      <i class="pi pi-circle-on"></i>
                    </div>
                    }
                    <div class="col-12 col-md-12 d-flex justify-content-end">
                      <p class="bes-color-black mt-1 alegreyasans-black">
                        {{ item.product.priceCredit }}
                      </p>
                      <img
                        class="fh-16 ms-2 mt-2"
                        src="./assets/project/Grupo 23.png"
                        alt="coins"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 bes-h-100">
                  <img
                    class="w-full h-full object-fit-cover"
                    [src]="item.product.image.imageUrl"
                    alt=" ticket"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <p
                class="bes-color-clean d-flex justify-content-center alegreyasans-black fs-5 bes-fs-25 fs-2 mt-2"
              >
                {{ item.product.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="w-full d-flex">
          <div
            class="content-bottom bes-w-95-perc bes-h-20 bes-bg-color-primary border border-top-0 border-end-0 bes-border-color-secondary border-2"
          ></div>
          <div class="bes-w-5-perc borda-quadrada-interna"></div>
        </div>
      </div>
    </div>
  </div>
</div>
