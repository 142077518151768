import { Component, OnInit } from '@angular/core';
import { HospLoadingService } from '../../core/services/loading.service';

@Component({
  selector: 'hosp-loading',
  standalone: false,
  templateUrl: './loading.component.html',
})
export class HospLoadingComponent implements OnInit {
  constructor(
    private loadingService: HospLoadingService
  ) {}

  ngOnInit(): void {
    this.watchService();
  }

  showLoading = false;
  watchService = () => {
    this.loadingService.loadingSource.subscribe((resp: boolean) => {
      this.showLoading = resp;
    });
  };
}
