<div class="container-fluid mt-5">
  <div class="row">
    <div class="col-12 p-0">
      <p-carousel
        styleClass="carousel"
        [value]="banners"
        [numVisible]="1"
        [numScroll]="1"
        [circular]="false"
        [showNavigators]="true"
        [showIndicators]="true"
        autoplayInterval="3000"
      >
        <ng-template let-banner pTemplate="item">
          <div class="mb-3 font-medium" (click)="banner.action()">
            <img
              class="rounded w-full border-0"
              [src]="banner.url"
              alt="box-open"
            />
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

  <div class="container-fluid mt-5 mb-5">
    <div class="row mt-4 mb-4">
      <div class="col-12 d-flex justify-content-center">
        <p-button
          styleClass="rounded-6 alegreyasans-black bes-fs-24 gradient-btn-primary-clean border-0 py-4"
          label="JOGUE ON-LINE AGORA"
          (onClick)="clickGameOrLogin()"
          severity="info"
        />
      </div>
    </div>
  </div>
</div>

<toy-modal-login #ml></toy-modal-login>
