import { Component, Input, OnInit } from '@angular/core';
import { AccessService } from '../../core/services/access.service';
import { Router } from '@angular/router';

@Component({
  selector: 'toy-colapse',
  templateUrl: './colapse.component.html',
})
export class ToyColapseComponent implements OnInit {
  constructor(private accessService: AccessService, private _router: Router) {}

  countClick: number = 0;
  carrinhoQtd: number = 0;
  ticket: number = 0;
  credito: number = 0;

  @Input() ambienteDetails: any;
  @Input() isPainel: boolean = true;

  ngOnInit(): void {
    this.getSaldo();
  }

  getSaldo = () => {
    console.log('Primeiro Get');
  };

  tabs = [
    {
      title: 'QUANTO TEMPO LEVA O ENVIO?',
      content:
        'O tempo de envio pode variar bastante dependendo de vários fatores, como o tipo de serviço de entrega que você escolheu, a distância entre o remetente e o destinatário, e até mesmo o país de origem e destino. Se você estiver falando sobre um envio nacional ou internacional, o prazo pode ser diferente.',
    },
    {
      title: 'HOUVE UM PROBLEMA COM MEU JOGO. O QUE DEVO FAZER?',
      content: `<p>Verifique as Atualizações: Certifique-se de que o jogo e sua plataforma (como PC, console ou dispositivo móvel) estão atualizados com a versão mais recente.</p>
      <p>Reinicie o Jogo e o Dispositivo: Às vezes, reiniciar o jogo ou o dispositivo pode resolver problemas temporários.</p>
      <p>Verifique os Requisitos do Sistema: Garanta que seu dispositivo atende aos requisitos mínimos do sistema para o jogo.</p>
      <p>Verifique a Conexão com a Internet: Se o problema estiver relacionado a jogos online, uma conexão instável pode ser a causa. Teste sua conexão e, se possível, reinicie seu roteador.</p>
      <p>Verifique Configurações e Preferências: Às vezes, problemas podem ser resolvidos ajustando as configurações do jogo ou redefinindo preferências</p>`,
    },
    {
      title: 'CONSULTE O SUPORTE AO CLIENTE:',
      content: `
      <p>Acesse o site oficial do jogo ou da plataforma e procure a seção de suporte.</p>
      <p>Como faço para usar o codigo de cupom? Encontre a Opção para Inserir o Código: Na página do carrinho ou de checkout, procure um campo ou botão que mencione “Código de Cupom”, “Código Promocional”, “Desconto” ou algo semelhante. Geralmente, este campo está localizado perto do total da compra ou na etapa final do checkout.</p>
      <p>Digite o Código: Insira o código de cupom no campo apropriado. Certifique-se de digitar o código corretamente, pois ele pode ser sensível a maiúsculas e minúsculas.</p>
      <p>Aplique o Cupom: Clique no botão para aplicar o código. Isso pode ser um botão que diz “Aplicar”, “Adicionar” ou algo semelhante. O desconto deve ser refletido no total da compra</p>`,
    },
  ];
}
