<div class="modal-pay-style gap-3">
  <p-dialog
    header="PAGAMENTO VIA PIX"
    [modal]="true"
    [(visible)]="showModalPay"
    (onHide)="onModalClose()"
    [draggable]="false"
    [resizable]="false"
    styleClass="box-modal"
    [style]="{ width: '25rem' }"
  >
    @if(dados){ @if (showModalPayQrcode) {
    <qr-code
      [value]="dados.qrcodeValue"
      size="250"
      darkColor="#241c35"
      lightColor="#FFF"
      errorCorrectionLevel="M"
      centerImageSrc="./assets/project/fortuneplush.quad.png"
      centerImageSize="120"
    >
    </qr-code>
 
    <div class="d-flex align-items-center gap-2 ms-4">
      <div
        class="bes-w-250 border bes-border-color-quarternary-clean rounded-3 p-2"
      >
        <p
          class="qr-code bes-fs-14 bes-color-quarternary-clean"
          style="width: 100%; word-break: break-all"
        >
          {{ dados.qrcodeValue }}
        </p>
      </div>
      <i
        pTooltip="Copiar"
        class="pi pi-copy cursor-pointer bes-color-quarternary-clean"
        style="font-size: 1.2rem"
        (click)="copiarValor()"
      ></i>
    </div>
    <p-button
      styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0 mt-4"
      label="PAGAMENTO REALIZADO"
      [rounded]="true"
      [raised]="true"
      severity="info"
      (onClick)="setPayment()"
    />
    } @if (showModalPayWait) {
    <div
      class="d-flex flex-column justify-content-evenly align-items-center"
      style="height: 100%"
    >
      <div class="d-flex flex-column align-items-center gap-3">
        <i
          class="pi pi-check-circle bes-color-tertiary-clean"
          style="font-size: 7rem"
        ></i>
        <h1 class="bes-fs-25 bes-color-tertiary-clean text-center">
          PAGAMENTO EM PROCESSAMENTO
        </h1>
      </div>
      <p class="bes-fs-16 bes-color-quaternary-clean text-center mt-5">
        O pagamento está em processamento e pode levar até 2 minutos para ser
        confirmado.
      </p>
      <p-button
        styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0 mt-4"
        label="JOGAR"
        [rounded]="true"
        [raised]="true"
        severity="info"
        (onClick)="redirectMaquinas()"
      />
    </div>
    } }
  </p-dialog>
</div>
