import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BESModalService, IModal } from '../../core/services/modal.service';
import { ToyCadastroComponent } from '../cadastro/cadastro.component';

@Component({
  selector: 'toy-modal-cadastro',
  standalone: false,
  templateUrl: 'modal-cadastro.component.html',
})
export class ToyModalCadastroComponent implements OnInit {
  constructor(private modalService: BESModalService) {}

  
  @ViewChild('mc') mc?: any;
  @ViewChild(ToyCadastroComponent) cadastroComponent!: ToyCadastroComponent;

  ngOnInit(): void {
    // this.watchService();
  }

  showModalCadastro = false;

  exibirModalCadastro = () => {
    this.showModalCadastro = true;
    if (this.cadastroComponent) {
      this.cadastroComponent.resetFormAndError();
    }
  };

  doCadastroSuccess = () =>{
    this.showModalCadastro = false;
  }
  
  // configModal?: IModal;

  // watchService = () => {
  //   this.modalService.modalSource.subscribe((resp: any) => {
  //     this.showModalCadastro = this.modalService.modalState;
  //     this.configModal = resp;
  //   });
  // };

  // close = () => {
  //   this.modalService.hide();
  // };

  // confirm = () => {
  //   if (this.configModal?.btnConfirmEvent) {
  //     this.configModal.btnConfirmEvent();
  //   }

  //   this.modalService.hide();
  // };

}
