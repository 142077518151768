import { NgModule } from '@angular/core';
import { ToyModalCadastroComponent } from './modal-cadastro.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToyCadastroModule } from '../cadastro/cadastro.module';

@NgModule({
  imports: [ButtonModule, DialogModule, ToyCadastroModule],
  declarations: [ToyModalCadastroComponent],
  exports: [ToyModalCadastroComponent],
})
export class ToyModalCadastroModule {}