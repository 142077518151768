<p-dialog
  header="CADASTRE-SE"
  [modal]="true"
  [(visible)]="showModalCadastro"
  [draggable]="false"
  [resizable]="false"
  styleClass="box-modal"
  [style]="{ width: '25rem' }">

  <toy-cadastro (onCadastroSuccess)="doCadastroSuccess()"></toy-cadastro>
</p-dialog>