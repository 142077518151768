import { NgModule } from '@angular/core';
import { ToyModalPayComponent } from './modal-pay.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToyLoginModule } from "../login/login.module";
import { QrCodeModule } from 'ng-qrcode';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [ButtonModule, DialogModule, ToyLoginModule, QrCodeModule, TooltipModule],
  declarations: [ToyModalPayComponent],
  exports: [ToyModalPayComponent],
})
export class ToyModalPayModule {}