import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioController } from '../../core/controllers/usuarios.controller';
import { CoinService } from '../../core/services/coin.service';

@Component({
  selector: 'toy-troca-card',
  templateUrl: './troca-card.component.html',
})
export class TrocaCardComponent implements OnInit {
  constructor(
    private _router: Router,
    private coinService: CoinService,
    private usuarioController: UsuarioController
  ) {}

  @Input() item: any;

  ngOnInit(): void {}

  trocarTicketCard = (value: number) => {
    
    this.usuarioController.trocarFichas({ value }).subscribe({
      next: (r) => {
        this.coinService.changed();
      },
    });
  };
}
