import { Injectable } from '@angular/core';
import { ApiBESService } from '../services/api-bes.service';

@Injectable({ providedIn: 'root' })
export class UsuarioController {
  constructor(private api: ApiBESService) {}
  private prefix = '/usuario';

  getSaldo = () => {
    return this.api.get(`${this.prefix}/saldo`);
  };

  getCart = () => {
    return this.api.get(`${this.prefix}/cart`);
  };

  getMine = () => {
    return this.api.get(`${this.prefix}/mine`);
  };

  getBalanceHistory = (mes: number) => {
    return this.api.get(`${this.prefix}/balance-history-value/${mes}`);
  };

  getAllUsuarios = () => {
    return this.api.get(`${this.prefix}`);
  };

  getUsuarioById = (id: number) => {
    return this.api.get(`${this.prefix}/${id}`);
  };

  createMyself = (body: any) => {
    return this.api.post(`${this.prefix}/myself`, body);
  };

  update = (body: any) => {
    return this.api.put(`${this.prefix}`, body);
  };

  updateCreditos = (body: any) => {
    return this.api.post(`${this.prefix}/value-credit`, body);
  };

  trocarFichas = (body: any) => {
    return this.api.put(`${this.prefix}/value-ticket`, body);
  };

  deleteUsuario = (id: number) => {
    return this.api.delete(`${this.prefix}/${id}`);
  };

  validarValorSaque = () => {
    return this.api.get(`${this.prefix}/validar-saque`);
  };

  cashout = (body: any) => {
    return this.api.put(`${this.prefix}/cash-out`, body);
  };

  getAllPorTipo = (tipoId: number) => {
    return this.api.get(`${this.prefix}/tipo/${tipoId}`);
  };

  salvarTipoUsuario = (id: number, body: any) => {
    return this.api.put(`${this.prefix}/tipo-usuario/${id}`, body);
  };

  deleteAfiliados = (id: number) => {
    return this.api.delete(`${this.prefix}/afiliados/${id}`, {});
  };
}
