import { NgModule } from '@angular/core';
import { ToyHeaderComponent } from './header.component';
import { ButtonModule } from 'primeng/button';
import { MaquinaCardModule } from "../maquina-card/maquina-card.module";
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToyModalCadastroModule } from '../modal-cadastro/modal-cadastro.module';
import { ToyModalLoginModule } from '../modal-login/modal-login.module';

@NgModule({
  imports: [ButtonModule, MaquinaCardModule, DividerModule, ImageModule, TieredMenuModule, ToyModalCadastroModule, ToyModalLoginModule],
  declarations: [ToyHeaderComponent],
  exports: [ToyHeaderComponent],
})
export class ToyHeaderModule {}
