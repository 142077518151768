<form [formGroup]="form">
  <input
    formControlName="email"
    type="text"
    placeholder="Email"
    class="inputEmail mb-4"
    (focus)="clearErrorMessage()"
  />
  <input
    formControlName="password"
    #passwordInput
    type="password"
    placeholder="Senha"
    class="inputSenha mb-4"
    (focus)="clearErrorMessage()"
  />

  @if (errorMessage) {
  <div class="text-center mb-3 bes-color-destructive">
    <i class="pi pi-times-circle me-1"></i>{{ errorMessage }}</div>
  }

  <button class="btn-login" (click)="fazerLogin()">LOGIN</button>

  <div class="forgot mt-2">
    <p>Esqueceu sua senha?</p>
    <span>Redefinir senha</span>
  </div>
</form>
