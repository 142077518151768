<toy-header [isPainel]="isPainel"></toy-header>

<div class="{{ isPainel ? 'container-fluid' : 'container' }}">
  <div class="row">
    <div class="{{ isPainel ? 'col-2 col-md-2' : 'd-none' }}">
      @if(isPainel){
      <toy-sidebar></toy-sidebar>
      }
    </div>
    <div
      class="{{
        isPainel ? 'col-10 col-md-10 bes-bg-color-clean body-painel' : 'col-12'
      }}"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
  <toy-footer [isPainel]="isPainel"></toy-footer>
<toy-modal-login></toy-modal-login>
