import { NgModule } from '@angular/core';
import { ToyModalLoginComponent } from './modal-login.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToyLoginModule } from "../login/login.module";

@NgModule({
  imports: [ButtonModule, DialogModule, ToyLoginModule],
  declarations: [ToyModalLoginComponent],
  exports: [ToyModalLoginComponent],
})
export class ToyModalLoginModule {}