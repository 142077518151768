<div class="row">
  <div
    class="d-flex justify-content-center align-items-center"
    (click)="comprarFichaCard(item);"
  >
    <div class="background-card"></div>
    <div
      class="surface-card d-flex flex-column justify-content-center align-items-center gap-2"
    >
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-6">
          <img
            class="moedas-icon"
            src="../../../assets/project/PPlushcoin.png"
            alt="Moedas"
          />
        </div>
        <div class="qtd-fichas col-6">
          <p>{{ item.ticket }}</p>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <p>BRL {{ item.price }},00</p>
        </div>
      </div>
    </div>
  </div>
</div>