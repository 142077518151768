<div class="container mt-md-5">
  <div class="row ms-md-5 me-md-5">
    <div class="d-none d-md-block col-6 bes-bg-color-clean">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center mt-5">
          <div class="row">
            <div class="col-12 py-5">
              <p
                class="alegreyasans-black bes-fs-28 bes-color-secondary-clean text-center"
              >
                LOADING…
              </p>
              <img
                class="d-flex justify-content-center"
                src="./assets/project/loading.gif"
                alt="img aqui"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-12 col-md-6 nopadding bes-bg-color-purple-clean"
      style="overflow: hidden"
    >
      <div class="row d-block d-md-none">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <img class="" src="./assets/project/loading.gif" alt="img aqui" />
        </div>
      </div>
      <div class="row">
        <div class="d-none d-md-block col-1 mt-5">
          <div class="borda-bottom borda-left borda-top"></div>
        </div>

        <div class="col-12 col-md-11 mt-2">
          <div class="mt-3 texto">
            <p
              class="alegreyasans-black bes-fs-28 text-center bes-color-clean texto py-2"
            >
              {{ userName || 'ANGUÉM' }} ESTÁ JOGANDO.
            </p>
          </div>
        </div>
        <div class="d-none d-md-block col-1 mt-5">
        </div>
        <div class="col-12 col-md-11 mt-2">
          <div class="mt-3 texto">
            <p
              class="alegreyasans-black bes-fs-28 text-center bes-color-clean texto py-2"
            >
             VOCÊ É O NÚMERO {{ qtdInLine }} DA FILA.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          @if(!isInLine) {
          <p-button
            styleClass="rounded-4 p-button-label bes-fs-24 bes-bg-color-tertiary-light bes-color-secondary-clean border-0 ps-4 pe-4 mb-5"
            label="ENTRAR NA FILA"
            (click)="entrarNaFila()"
            [rounded]="true"
            [raised]="true"
            severity="info"
          />
          }@else {
          <p-button
            styleClass="rounded-4 p-button-label bes-fs-24 bes-bg-color-tertiary-light bes-color-secondary-clean border-0 ps-4 pe-4 mb-5"
            label="SAIR DA FILA"
            (click)="sairDaFila()"
            [rounded]="true"
            [raised]="true"
            severity="info"
          />
          }
        </div>
      </div>
    </div>
  </div>
</div>
