import { Component, Input, OnInit } from '@angular/core';
import { AccessService } from '../../core/services/access.service';
import { Router } from '@angular/router';

@Component({
  selector: 'toy-footer',
  templateUrl: './footer.component.html',
})
export class ToyFooterComponent implements OnInit {
  constructor(private accessService: AccessService, private _router: Router) {}

  @Input() isPainel: boolean = true;

  ngOnInit(): void {}

  links = () => {
    console.log('deu certo');
  };

  isFooterOpen = false
  isFooterAccountOpen = false;

  clickFooter = () => {
    this.isFooterOpen = !this.isFooterOpen;
    if (this.isFooterAccountOpen) {
      this.isFooterAccountOpen = false
    }
  };

  clickAccount = () => {
    this.isFooterAccountOpen = !this.isFooterAccountOpen;
    if (this.isFooterOpen) {
      this.isFooterOpen = false
    }
  }

  clickMaquinas = () => {
    this._router.navigate(['maquina']);
    this.isFooterAccountOpen = false
    this.isFooterOpen = false
  }
  
  redirectConta = () => {
    this._router.navigate(['perfil/conta']);
    this.isFooterAccountOpen = false
  }
  redirectDepositoSaque = () => {
    this._router.navigate(['perfil/deposito-saque']);
    this.isFooterAccountOpen = false
  }
  redirectHistoricoJogos = () => {
    this._router.navigate(['perfil/historico-jogos'])
    this.isFooterAccountOpen = false
  }
  redirectCarrinho = () => {
    this._router.navigate(['carrinho']);
    this.isFooterAccountOpen = false
  }
  logout() {
    this.accessService.sair();
    this._router.navigate(['/']);
    this.isFooterAccountOpen = false
  }

  redirecTermsService = () => {
    this._router.navigate(['terms-service'])
  }
  redirecPrivacyPolicy = () => {
    this._router.navigate(['privacy-policy'])
  }
  redirecCookiesPolicy = () => {
    this._router.navigate(['cookies-policy'])
  }
  redirecSobreNos = () => {
    this._router.navigate(['about-us'])
  }

}
