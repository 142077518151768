<div class="home-mobile-container">
    <!-- <div class="logotipo">
        <img src="./assets/project/Fortune Plush logo.png" alt="logotipo">
    </div> -->
    <div class="start-title mt-5">
        <h1 class="mt-5">COMECE POR AQUI</h1>
    </div>
    <div class="start-btn-cadastro">
        <p-button
            styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0"
            label="CADASTRE-SE"
            [rounded]="true"
            [raised]="true"
            severity="info"
            (onClick)="redirectCadastroMobile()"
        />
    </div>
    <div class="start-btn-login">
        <p-button
            styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0"
            label="LOGIN"
            [rounded]="true"
            [raised]="true"
            severity="info"
            (onClick)="redirectLoginMobile()"
        />
    </div>
    <div class="forgot">
        <p>Esqueceu sua senha?</p>
        <p class="alt-pswrd">Redefinir senha</p>
    </div>
</div>
<toy-modal-login #ml></toy-modal-login>