<div class="sidebar-container row">
  <div class="sidebar-body w-full p-0">
    @for (m1 of menu; track $index) {
    <div>
      <div class="menu {{ m1.active && 'active' }}">
        <div
          class="conteudo conteudo-m1  {{
            m1.active && m1.type == 2 && 'conteudo-active'
          }}"
          (click)="clickMenu($event, m1)"
        >
          <p>{{ m1.description }}</p>
          @if(m1.type == 1) {
          <i
            class="pi {{ m1.active ? ' pi-angle-down' : ' pi-angle-right' }}"
          ></i>
          }
        </div>

        @if(m1.active) { @for (m2 of m1.children; track $index) {
        <div>
          <div class="menu {{ m2.active && 'active' }}">
            <div
              class="conteudo conteudo-m2 {{
                m2.active && m2.type == 2 && 'conteudo-active'
              }}"
              (click)="clickMenu($event, m2, m1)"
            >
              <p>{{ m2.description }}</p>
              @if(m2.type == 1) {
              <i
                class="pi {{
                  m2.active ? ' pi-angle-down' : ' pi-angle-right'
                }}"
              ></i>
              }
            </div>
            @if(m2.active) { @for (m3 of m2.children; track $index) {
            <div>
              <div class="menu {{ m3.active && 'active' }}">
                <div
                  class="conteudo conteudo-m3 {{
                    m3.active && m3.type == 2 && 'conteudo-active'
                  }}"
                  (click)="clickMenu($event, m3, m1, m2)"
                >
                  <p>{{ m3.description }}</p>
                </div>
              </div>
            </div>
            } }
          </div>
        </div>
        } }
      </div>
    </div>
    }
  </div>
</div>
