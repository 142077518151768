<div class="container-fluid gradient-tertiary-clean">
  <br />
  <div class="mt-4">
    <h1
      class="text-center alegreyasans-black bes-color-primary-clean bes-fs-30 mb-4"
    >
      COMO JOGAR?<br />
      PERGUNTAS FREQUENTES
    </h1>
  </div>
  <div class="row">
    <div class="col-10 col-md-8 offset-1 offset-md-2 mb-5">
      <p-accordion
        [activeIndex]=""
        expandIcon="pi pi-angle-double-right p-icon"
        collapseIcon="pi pi-angle-double-down p-icon"
      >
        @for(tab of tabs; track $index){

        <p-accordionTab [header]="tab.title">
          <p class="m-0" [innerHTML]="tab.content"></p>
        </p-accordionTab>
        }
      </p-accordion>
    </div>
  </div>
  <br />
</div>
