import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'toy-avaliacao',
  templateUrl: './avaliacao.component.html',
})
export class ToyAvaliacaoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  
}
