import { NgModule } from '@angular/core';
import { ToyLoadingMaquinaComponent } from './loading-maquina.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [ButtonModule],
  declarations: [ToyLoadingMaquinaComponent],
  exports: [ToyLoadingMaquinaComponent],
})
export class ToyLoadingMaquinaModule {}
