<div class="row">
    <div class="perfil-menu d-none d-md-block col-12 col-md-3">
        <p-tieredMenu
          #menu
          [model]="items"
          class="custom-menu"
          styleClass="menu-lateral-perfil"
        ></p-tieredMenu>
    </div>
    
    <div class="col-12 col-md-9">
        <router-outlet></router-outlet>
    </div>
</div>