<div class="modal-painel-produto gap-3">
  <p-dialog
    [header]="title"
    [modal]="true"
    [(visible)]="showModalDialog"
    (onHide)="onModalClose()"
    [draggable]="false"
    [resizable]="false"
    styleClass="box-modal"
    [style]="{ width: '25rem' }"
  >
    <div class="row">
      <div class="col-12">
        <h1 class="alegreyasans-black bes-fs-22 bes-color-clean">
          {{ subtitle }}
        </h1>
      </div>
    </div>

    <p-button
      styleClass="rounded-4 alegreyasans-black bes-fs-18 gradient-btn-tertiary border-0 mt-4 px-5"
      label="OK"
      [rounded]="true"
      [raised]="true"
      severity="info"
      (onClick)="onModalClose()"
    />
  </p-dialog>
</div>
