<div class="row">
    <div
      class="d-flex justify-content-center align-items-center"
      (click)="trocarTicketCard(item.ticket)"
    >
      <div class="background-card"></div>
      <div
        class="surface-card d-flex flex-column justify-content-center align-items-center gap-2"
      >
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-6">
            <img
              class="moedas-icon"
              src="../../../assets/project/dinheiro.png"
              alt="Moedas"
            />
          </div>
          <div class="qtd-fichas col-6">
            <p>{{ item.ticket }}</p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <p>
                <img class="bes-w-20" src="../../../assets/project/FontAwsome (ticket-alt).png">
                 {{ item.price }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  