import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion';
import { ToyHomeComponent } from './home.component';
import { ToyModalCadastroModule } from '../modal-cadastro/modal-cadastro.module';
import { ToyModalLoginModule } from '../modal-login/modal-login.module';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  imports: [
    ButtonModule,
    DividerModule,
    CarouselModule,
    AccordionModule,
    ToyModalCadastroModule,
    ToyModalLoginModule,
  ],
  declarations: [ToyHomeComponent],
  exports: [ToyHomeComponent],
})
export class ToyHomeModule {}
