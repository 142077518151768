import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToyModalJogoComponent } from './modal-jogo.component';

@NgModule({
  imports: [ButtonModule, DialogModule],
  declarations: [ToyModalJogoComponent],
  exports: [ToyModalJogoComponent],
})
export class ToyModalJogoModule {}